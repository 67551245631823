<template>
  <div class="safety ">
    <div style="width: 100%; height: 80px; background-color: #FFFFFF">
      <div style="display: flex; justify-content: space-between; padding-top: 16px; width: 1500px; margin: 0 auto">
        <img src="@/assets/imgs/index/safety.png" style="width: 334px; height: 49px">
        <div>
          <AvatarMenu></AvatarMenu>
        </div>
      </div>
    </div>
    <div class="body">
      <Security></Security>
    </div>
  </div>
</template>

<script>

import Security from "@/pages/user/components/Security.vue";
import AvatarMenu from "@/components/common/AvatarMenu.vue";

export default {
  name:'Safety',
  components: {
    AvatarMenu,
    Security,
  },
  data() {
    return {}
  },
  props:{
  },
  computed:{
    user() {
      return this.$store.state.user
    },
  },
  created(){
  },
  mounted() {

  },
  watch: {
  },
  methods: {
    changeNav(e) {
      this.type = e
    },
  }

}
</script>

<style scoped>
.safety {
  min-height: 100vh;
  background-color: #F2F2F2;
}

.safety  .body {
  width: 1500px;
  margin: 10px auto
}

.safety  .nav-box {
  display: flex;
  color: #A6A9B7;
  font-size: 18px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
}

.safety  .select-box {
  background-color: #F1F5F8;
  color: #031F88;
}

.checkout-btn {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0;
  color: #EE3F4D;
  border: 1px solid #EE3F4D;
}

.select-btn {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0;
  color: #FFFFFF;
  background-color: #EE3F4D;
  border: 1px solid #EE3F4D;
}

</style>
